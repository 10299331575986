import React from 'react';
import * as styles from './ServicesInfo.module.scss';
import { Container } from 'react-bootstrap';
import SectionTitle from '../SectionTitle/SectionTitle';
import { StaticImage } from 'gatsby-plugin-image';

const ServicesInfo = ({ html, wordpress }) => {
  return (
    <section className={styles.services_info}>
      <Container>
        <div className={styles.wrapper}>
          <div
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={`${styles.card} ${styles.down}`}
          >
            <SectionTitle title="My Service" subtitle="How does it work?" />
          </div>
          {html && (
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
              className={`${styles.card} ${styles.up}`}
            >
              <StaticImage
                src="../../images/kod.webp"
                alt="Image showing manual coding of a website"
                width={330}
                height={440}
              />
              <div className={styles.card_text}>
                <h3>Clean and Aesthetic Code</h3>
                <p>
                  <em>I create websites</em> based on clean, readable code. This not only facilitates future
                  modifications but also positively impacts search engine positioning, increasing the visibility of your
                  site.
                </p>
              </div>
            </div>
          )}
          {wordpress && (
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="1000"
              className={`${styles.card} ${styles.up}`}
            >
              <StaticImage
                src="../../images/wordpress.webp"
                alt="wordpress - websites based on WordPress"
                width={330}
                height={440}
              />
              <div className={styles.card_text}>
                <h3>CMS – Easy Content Management</h3>
                <p>
                  I offer <em>website development</em> based on content management systems (CMS), such as{' '}
                  <em>WordPress</em>. This gives you full control over your site, allowing you to independently add
                  content and update the site without needing coding knowledge.
                </p>
              </div>
            </div>
          )}
          <div
            data-sal="slide-left"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={`${styles.card} ${styles.down}`}
          >
            <StaticImage
              src="../../images/web_design.webp"
              alt="web design - website design"
              width={330}
              height={440}
            />
            <div className={styles.card_text}>
              <h3>Modern Design</h3>
              <p>
                Each website is designed with the unique needs of your brand in mind. I create modern, aesthetic, and
                functional websites that perfectly reflect your business's character and attract customer attention.
              </p>
            </div>
          </div>
          <div
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={`${styles.card} ${styles.up}`}
          >
            <StaticImage
              src="../../images/wspolpraca.webp"
              alt="cooperation - support and collaboration in website development"
              width={330}
              height={440}
            />
            <div className={styles.card_text}>
              <h3>Support and Collaboration</h3>
              <p>
                Throughout the <em>website development</em> process, you will be informed about the progress. We
                collaborate on the project to ensure the site fully meets your expectations and is completed on time.
              </p>
            </div>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={`${styles.card} ${styles.down}`}
          >
            <StaticImage
              src="../../images/galeria.webp"
              alt="graphics and photos - professional images and graphics for a website"
              width={330}
              height={440}
            />
            <div className={styles.card_text}>
              <h3>Graphics and Photos</h3>
              <p>
                I have access to a professional database of graphics and photos that can be used on your site. This
                ensures that your website is not only functional but also visually attractive and aesthetic.
              </p>
            </div>
          </div>
          <div
            data-sal="slide-left"
            data-sal-delay="100"
            data-sal-duration="1000"
            className={`${styles.card} ${styles.up}`}
          >
            <StaticImage
              src="../../images/rwd.webp"
              alt="responsiveness - responsive websites"
              width={330}
              height={440}
            />
            <div className={styles.card_text}>
              <h3>Responsiveness (RWD)</h3>
              <p>
                <em>Every website I create</em> is fully responsive. This means it will look and perform excellently on
                all devices, from smartphones to laptops, ensuring an optimal user experience.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ServicesInfo;
