import * as React from 'react';
import '../styles/global.scss';
import '../styles/normalize.scss';
import '../styles/typography.scss';
import '../styles/settings.scss';
import Headers from '../components/Headers/Headers';
import SiteInfoServices from '../components/SiteInfoServices/SiteInfoServices';
import { strony_niestandardowe_txt } from '../consts/texts';
import ServicesInfo from '../components/ServicesInfo/ServicesInfo';
import Prices from '../components/Prices/Prices';
import Footer from '../components/Footer/Footer';
import RootLayout from '../components/RootLayout/RootLayout';
import ScroolUpButton from '../components/ScroolUpButton/ScroolUpButton';
import ScroolToPriceButton from '../components/ScroolToPriceButton/ScroolToPriceButton';

const StronyNiestandardowe = () => {
  const title_top = 'Websites';
  const title_middle = '100% Coded';
  const title_bottom = 'in HTML/CSS/JS';
  const subtitle =
    'I create unique websites in pure HTML, providing full control over the code, high performance, and unlimited customization options.';

  const siteTitle = 'HTML/CSS/JS Websites – Unique Solutions Without CMS';
  const siteDescription =
    'I develop custom-coded websites from scratch, without CMS limitations. Modern, optimized, and tailored to your business’s unique needs, ideal for unconventional projects.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/custom-websites/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/strony-niestandardowe/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers
        title_top={title_top}
        title_middle={title_middle}
        title_bottom={title_bottom}
        subtitle={subtitle}
        active="html"
      />
      <ScroolUpButton />
      <ScroolToPriceButton />
      <SiteInfoServices
        title_first_column={strony_niestandardowe_txt.title_first_column}
        title_first_card={strony_niestandardowe_txt.title_first_card}
        title_second_card={strony_niestandardowe_txt.title_second_card}
        descryption_first_column={strony_niestandardowe_txt.descryption_first_column}
        card_descryption={strony_niestandardowe_txt.card_descryption}
        second_card_descryption={strony_niestandardowe_txt.second_card_descryption}
      />
      <ServicesInfo html />
      <Prices html />
      <Footer />
    </>
  );
};

export default StronyNiestandardowe;
